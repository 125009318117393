// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

function DailyWinners() {
  const [dateTo, setDateTo] = useState(new Date(moment.utc()));
  const [dateFrom, setDateFrom] = useState(new Date(moment.utc()));
  const [data, setData] = useState();
  const [canUpdate, setCanUpdate] = useState(true);
  const [bets, setBets] = useState();
  useEffect(() => {
    if (!data) return;
    const rows = data.map((b) => {
      return {
        user: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography
              component="a"
              href={"/user/" + b.address}
              color="text"
            >
              {b.nick || shortened(b.address)}
            </MDTypography>
          </MDBox>
        ),
        ip: b.ip,
        count: b.count,
        timeframe: b.timeframe.toFixed(),
        winRate: b.winRate,
        profit: b.profit,
      };
    });

    const columns = [];
    columns.push({
      Header: "user",
      accessor: "user",
      align: "left",
    });
    columns.push({ Header: "ip", accessor: "ip", align: "left" });
    columns.push({ Header: "count", accessor: "count", align: "left" });
    columns.push({
      Header: "avg timeframe",
      accessor: "timeframe",
      align: "left",
    });
    columns.push({ Header: "winRate", accessor: "winRate", align: "left" });
    columns.push({ Header: "profit USD", accessor: "profit", align: "left" });
    const items = {
      columns,
      rows,
    };

    setBets(items);
  }, [data]);

  useEffect(() => {
    const load = async () => {
      if (!canUpdate) return;
      const result = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/v1/dashboard/stats/dailyWinners`,
        {
          params: {
            from: dateFrom,
            to: dateTo,
          },
          withCredentials: true,
        }
      );
      const winners = [];
      result.data.statsPro.forEach((b) => {
        winners.push({
          address: b._id.sender,
          profit: Math.floor(b.amountWon - b.totalBet),
          count: b.count,
          winRate: (100 * (b.won / b.count)).toFixed(),
          nick: b.nick,
          ip: b.ip,
          timeframe: b.timeframe,
        });
      });

      setData(winners.sort((a, b) => b.profit - a.profit));
    };

    load();
  }, [canUpdate, dateTo, dateFrom]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {data && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="black">
                    Daily Top Winners <br />
                    From the beginning of:{" "}
                    <DatePicker
                      selected={dateFrom}
                      onChange={(d) => setDateFrom(d)}
                    />
                    To the end of:{" "}
                    <DatePicker
                      selected={dateTo}
                      onChange={(d) => setDateTo(d)}
                    />
                    <br />
                    Profit represents user profit. +: user won, -: house won
                  </MDTypography>
                </MDBox>
                {bets && (
                  <MDBox pt={3}>
                    <DataTable
                      setCanUpdate={setCanUpdate}
                      table={bets}
                      isSorted={true}
                      canSearch={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default DailyWinners;
